import React from 'react';
import { Link } from 'react-router-dom';
import './About.css'; // If you have specific styles for the About page

const Privacy = () => {
    return (
        <div className="about-container">
            <h1>Verser Privacy Policy</h1>
            <p>Effective: February 12, 2024</p>
            
            <p>Welcome to Verser. Your privacy is important to us. This Privacy Policy explains how we collect, use, protect, and disclose information and data when you use the Verser website. By accessing or using our website, you agree to this Privacy Policy.</p>
            
            <h4>1. Information We Collect</h4>
            <h5>a. Information Collected Automatically</h5>
            
            <p>When you visit our website, we automatically collect certain information from your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the site, and information about how you interact with the site.</p>
            
            <h5>b. Cookies and Tracking Technologies</h5>
            
            <p>We use cookies, web beacons, and other tracking technologies to collect information about your browsing activities over time and across different websites following your visit to our website. This information helps us understand your preferences and improve your website experience. It also helps us serve more relevant ads.</p>
            
            <h4>2. How We Use Your Information</h4>
            
            <p>We use the information we collect to operate, maintain, and provide to you the features and functionality of the website, including to:</p>
            
            <ul>
                <li>Personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
                <li>Improve our website in order to better serve you.</li>
                <li>Allow us to better service you in responding to your customer service requests.</li>
                <li>Serve relevant ads to you when you visit our site or other sites.</li>
            </ul>
            
            <h4>3. Sharing Your Information</h4>
            
            <p>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when its release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</p>
            
            <h4>4. Third-party Links</h4>
            
            <p>Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>
            
            <h4>5. Children's Privacy</h4>
            
            <p>Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under age 13, we will delete that information as quickly as possible.</p>
            
            <h4>6. Changes to This Privacy Policy</h4>
            
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
            
            <h4>7. Contact Us</h4>
            
            <p>If you have any questions about this Privacy Policy, please contact us at contact@verserindex.com.</p>

        </div>
    );
};

export default Privacy;
