import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import './Edit.css';

const Edit = () => {
    const { interviewId } = useParams();
    const [comments, setComments] = useState('');
    const [interviewTitle, setInterviewTitle] = useState('');
    const [URL, setURL] = useState('');
    const [interviewDate, setInterviewDate] = useState('');
    const [Interviewees, setInterviewees] = useState('');
    const [Interviewers, setInterviewers] = useState('');
    const [Publisher, setPublisher] = useState('');
    const [Topics, setTopics] = useState('');
    const [People, setPeople] = useState('');
    const [Works, setWorks] = useState('');
    const [Places, setPlaces] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const navigate = useNavigate();
    const [removalReason, setRemovalReason] = useState({
        derivativeContent: false,
        newsContent: false,
        notAnInterview: false,
    });

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };


    useEffect(() => {
        document.title = "Edit interview - Verser";
        window.scrollTo(0, 0);
    
        const fetchInterviewDetails = async () => {
            const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
        
            // Helper function to format names with IDs
            const formatNamesWithIDs = (detailsString) => {
                if (!detailsString) return '';
                return detailsString.split('|')  // Split the string on '|'
                    .map(detail => {
                        const [name, id] = detail.split(':');  // Split each part into name and ID
                        return `${name.trim()} (${id.trim()})`;  // Format as "Name (ID)"
                    })
                    .join('; ');  // Join all formatted parts with '; '
            };
        
            // Helper function to replace '|' with '; '
            const replacePipeWithSemicolon = (detailsString) => {
                return detailsString?.replace(/\|/g, '; ') ?? '';
            };
        
            try {
                const response = await fetch(`${baseUrl}/api/interviews/${interviewId}`);
                if (response.ok) {
                    const data = await response.json();
                    setInterviewTitle(data.PageTitle ?? ''); 
                    setURL(data.URLs?.split('|')[0] ?? '');
                    setInterviewDate(data.Date.split('T')[0]);
                    setInterviewees(formatNamesWithIDs(data.IntervieweeDetails));
                    setInterviewers(formatNamesWithIDs(data.InterviewerDetails));
                    setPublisher(data.PublisherName ?? '');
                    setTopics(replacePipeWithSemicolon(data.TopicNames));
                    setPeople(formatNamesWithIDs(data.PeopleMentionedDetails));
                    setWorks(replacePipeWithSemicolon(data.WorkTitles));
                    setPlaces(replacePipeWithSemicolon(data.PlaceNames));
                } else {
                    alert('Failed to fetch interview details.');
                }
            } catch (error) {
                console.error('Error fetching interview details:', error);
                alert('Error fetching interview details.');
            }
        };
    
        fetchInterviewDetails();
    }, [interviewId]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

        const userId = process.env.REACT_APP_USER_ID || '123';

        const response = await fetch(`${baseUrl}/api/contributions/edit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId,
                interviewId,
                comments,
                interviewTitle,
                URL,
                interviewDate,
                Interviewees,
                Interviewers,
                Publisher,
                Topics,
                People,
                Works,
                Places,
                removalReason
            }),
        });

        if (response.ok) {
            setSubmitted(true);
        } else {
            alert('Error submitting the edit. Please try again.');
        }
    };

    const isFormEnabled = () => {
        return comments.trim() !== '' || interviewTitle.trim() !== '' || removalReason.derivativeContent || removalReason.newsContent || removalReason.notAnInterview;
    };

    if (submitted) {
        return (
            <div>
                <h2 className='edit-header'>Thank you for contributing!</h2>
                <p className='tips-thanks'>Please allow time for your contribution to be reviewed before it will appear on the site.</p>
                <Link to={`/interview/${interviewId}`}>Back to interview</Link>
            </div>
        );
    }

    return (
        <div>
            <h2 className='edit-header'>Edit this interview</h2>
            <form onSubmit={handleSubmit} className='edit-form'>

            <label>Title
                <div><input
                    type="text"
                    value={interviewTitle}
                    onChange={(e) => setInterviewTitle(e.target.value)}
                    placeholder="Add an interview title"
                    className="edit-input" 
                /></div>
            </label>

            <label>URL
                <div><input
                    type="text"
                    value={URL} 
                    onChange={(e) => setURL(e.target.value)} 
                    placeholder="Add a URL"
                    className="edit-input" 
                /></div>
            </label>

            <label>Date
                <div><input
                    type="date"
                    value={interviewDate} 
                    onChange={(e) => setInterviewDate(e.target.value)} 
                    className="edit-input" 
                /></div>
            </label>

            <label>Interviewees
            <div className='tips'>Separate names with semicolons, and if known, put person IDs in parentheses, like this: <i>Dolly Parton (7343); Barack Obama (120)...</i> Find IDs in person page URLs.</div>
            <textarea
                    value={Interviewees} 
                    onChange={(e) => setInterviewees(e.target.value)} 
                    placeholder="Add interviewees"
                />
            </label>

            <label>Interviewers
            <div className='tips'>Separate names with semicolons, and if known, put person IDs in parentheses, like this: <i>Dolly Parton (7343); Barack Obama (120)...</i> Find IDs in person page URLs.</div>
            <textarea
                    value={Interviewers} 
                    onChange={(e) => setInterviewers(e.target.value)} 
                    placeholder="Add interviewers"
                />
            </label>

            <label>Publisher
            <div className='tips'>If known, add publisher ID in parentheses, like this: <i>C-SPAN (12)</i> Find IDs in publisher page URLs.</div><div><input
                    type="text"
                    value={Publisher} 
                    onChange={(e) => setPublisher(e.target.value)} 
                    placeholder="Add a publisher"
                    className="edit-input" 
                /></div>
            </label>

            <label>Topics discussed
            <div className='tips'>Separate topics with semicolons.</div>
            <textarea
                    value={Topics} 
                    onChange={(e) => setTopics(e.target.value)}
                    placeholder="Add topics discussed"
                />
            </label>

            <label>People mentioned
            <div className='tips'>Separate names with semicolons, and if known, put person IDs in parentheses, like this: <i>Dolly Parton (7343), Barack Obama (120)...</i> Find IDs in person page URLs.</div>
            <textarea
                    value={People} 
                    onChange={(e) => setPeople(e.target.value)}
                    placeholder="Add people mentioned"
                />
            </label>

            <label>Works of art, scholarship, publishing, etc... mentioned
            <div className='tips'>Separate works with semicolons.</div>
            <textarea
                    value={Works}
                    onChange={(e) => setWorks(e.target.value)} 
                    placeholder="Add works mentioned"
                />
            </label>

            <label>Places mentioned
            <div className='tips'>Separate places with semicolons.</div>
            <textarea
                    value={Places} 
                    onChange={(e) => setPlaces(e.target.value)} 
                    placeholder="Add places mentioned"
                />
            </label>

            <label>Other comments
                <textarea
                    value={comments} 
                    onChange={(e) => setComments(e.target.value)} 
                    placeholder="Describe other errors or missing information"
                />
            </label>

            <div>
                <h3>Remove this interview</h3>
                <div className='checkboxes'>
                    <label>
                        <input
                            type="checkbox"
                            checked={removalReason.derivativeContent}
                            onChange={() => setRemovalReason({...removalReason, derivativeContent: !removalReason.derivativeContent})}
                        />
                        This is derivative content that summarizes or highlights an original interview
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={removalReason.newsContent}
                            onChange={() => setRemovalReason({...removalReason, newsContent: !removalReason.newsContent})}
                        />
                        This is news content focused on events, not on a subject/interviewee
                    </label>
                    <label>
                        <input
                            type="checkbox"
                            checked={removalReason.notAnInterview}
                            onChange={() => setRemovalReason({...removalReason, notAnInterview: !removalReason.notAnInterview})}
                        />
                        Something else
                    </label>
                </div>
            </div>

                <div><button type="submit" disabled={!isFormEnabled()}>Submit</button></div>
            </form>
            <p className='tips'>Please allow time for your contribution to be reviewed before it appears on the site.</p>
        </div>
    );
};

export default Edit;