import React, { useState, useEffect } from 'react';
import './Review.css';

const Review = () => {
    const [reviewData, setReviewData] = useState([]);
    const [filterStatus, setFilterStatus] = useState('null');

    // Base URL from environment variable or fallback to localhost
    const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

    // Fetch contributions data
    const fetchContributions = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/review/contributions`);
            if (response.ok) {
                const data = await response.json();
                setReviewData(data);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            console.error('Error fetching review data:', error);
            alert('Failed to fetch review data');
        }
    };

    // Initial data load
    useEffect(() => {
        fetchContributions();
    }, []);

    // Function to render summary as plain text
    const renderSummaryText = (summary) => {
        return summary ? (
            <ul className='review-summary'>
                {Object.entries(summary).map(([key, value], index) => {
                    // Adjust the regex to capture the content inside the quotes
                    const changeMatch = value.match(/Changed from '([^']*)' to '([^']*)'/);
                    return (
                        <li key={index}>
                            <b className='list-head'>{key}</b>
                            <ul>
                                <li>{changeMatch ? changeMatch[1] : 'N/A'}</li>
                                <li>{changeMatch ? changeMatch[2] : 'N/A'}</li>
                            </ul>
                        </li>
                    );
                })}
            </ul>
        ) : 'No changes';
    };
        

    // Function to create a Blob URL for the JSON of a contribution
    const jsonBlobUrl = (contribution) => {
        const jsonString = JSON.stringify(contribution, null, 2); // Pretty print the JSON
        const blob = new Blob([jsonString], { type: 'application/json;charset=utf-8' });
        return URL.createObjectURL(blob);
    };

    // Function to filter contributions based on status
    const filteredData = reviewData.filter(item =>
        filterStatus === 'all' ||
        (filterStatus === 'null' && item.contribution.Status === null) ||
        (item.contribution.Status && item.contribution.Status.toLowerCase() === filterStatus)
    );

    // Function to handle status change
    const handleStatusChange = (event) => {
        setFilterStatus(event.target.value);
    };

    // Handle Accept
    const handleAccept = async (contributionId) => {
        try {
            const response = await fetch(`${baseUrl}/api/review/contributions/accept`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ contributionId })
            });
            if (response.ok) {
                alert('Contribution accepted');
                fetchContributions(); // Refresh data
            } else {
                throw new Error('Failed to accept contribution');
            }
        } catch (error) {
            console.error('Error accepting contribution:', error);
            alert('Failed to accept contribution');
        }
    };

    // Handle Reject
    const handleReject = async (contributionId) => {
        try {
            const response = await fetch(`${baseUrl}/api/review/contributions/reject`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ contributionId })
            });
            if (response.ok) {
                alert('Contribution rejected');
                fetchContributions(); // Refresh data
            } else {
                throw new Error('Failed to reject contribution');
            }
        } catch (error) {
            console.error('Error rejecting contribution:', error);
            alert('Failed to reject contribution');
        }
    };


    return (
        <div className='review'>
            <h2>Review user contributions</h2>
            <div className='review-item'>
                <label htmlFor="status-filter">Filter by Status:</label>
                <select id="status-filter" onChange={handleStatusChange} value={filterStatus}>
                    <option value="all">All</option>
                    {/* <option value="pending">Pending</option> */}
                    <option value="accepted">Accepted</option>
                    <option value="rejected">Rejected</option>
                    <option value="null">Not Set</option>
                </select>
            </div>
            {filteredData.map((item, index) => (
                <div key={index} className='review-item'>
                    <a href={jsonBlobUrl(item.contribution)} target="_blank" rel="noopener noreferrer">
                        Contribution ID: {item.contribution.ContributionID}
                    </a><br />
                    <a href={`https://www.verserindex.com/interview/${item.contribution.InterviewID}`} target="_blank" rel="noopener noreferrer">
                        Interview ID: {item.contribution.InterviewID || 'N/A'}
                    </a><br />
                    User ID: {item.contribution.UserID}<br />
                    Submission Date: {item.contribution.SubmissionDate || 'N/A'}<br />
                    Status: {item.contribution.Status}<br />
                    Type: {item.contribution.ContributionType}<br />
                    Comments: {item.contribution.Comments || 'N/A'}<br /><br />
                    <b>Remove this interview?</b><br />
                    <ul className='review-summary'>
                        <li>
                            Derivative Content: {item.contribution.derivative_content ? <span className="highlight-yes">Yes</span> : 'No'}
                        </li>
                        <li>
                            News Content: {item.contribution.news_content ? <span className="highlight-yes">Yes</span> : 'No'}
                        </li>
                        <li>
                            Not An Interview: {item.contribution.not_an_interview ? <span className="highlight-yes">Yes</span> : 'No'}
                        </li>
                    </ul><br />
                    <b>Summary of changes from/to:</b> {renderSummaryText(item.summary)}
                    Actions: 
                    <button onClick={() => handleAccept(item.contribution.ContributionID)}>Accept</button>
                    <button onClick={() => handleReject(item.contribution.ContributionID)}>Reject</button>
                </div>
            ))}
        </div>
    );
};

export default Review;