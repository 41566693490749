// src/components/Search.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Search = ({ onSearch, defaultQuery = '' }) => {
    const [query, setQuery] = useState(defaultQuery);
    const [placeholder, setPlaceholder] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Extracts the query parameter from the URL and updates the query state
        const searchParams = new URLSearchParams(location.search);
        const currentQuery = searchParams.get('q');
        if (currentQuery) {
            setQuery(currentQuery);
        }
        // Set initial placeholder based on the current page
        setPlaceholder(getPlaceholderText());
    }, [location]);

    const getPlaceholderText = () => {
        // Check if the current page is the home page
        return location.pathname === '/' ? 
               "Search..." : 
               "Search...";
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!query.trim()) {
            // Do not proceed if query is empty or only contains whitespace
            return;
        }
        await onSearch(query);
        navigate(`/search?q=${encodeURIComponent(query)}`);
    };

    const handleInputKeyDown = (event) => {
        // Handle form submission when Enter or Go key is pressed
        if (event.key === 'Enter' || event.key === 'Go') {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={placeholder}
                onFocus={() => setPlaceholder('')} 
                onBlur={() => setPlaceholder(getPlaceholderText())}
                onKeyDown={handleInputKeyDown} // Handle Enter and Go key presses
            />
        </form>
    );
};

export default Search;
