import React from 'react';
import { Link } from 'react-router-dom';
import './About.css'; // If you have specific styles for the About page

const About = () => {
    return (
        <div className="about-container">
            <h1>About Verser</h1>
            <p>
            Verser is the internet's best index of interviews. It makes it easy to find interesting conversations with the most interesting people.
            </p>
            <p>The index relies on contributions from users. Please contribute by <b><Link to="/add">adding or updating interviews</Link></b>.
            </p> 
            <p>All inquiries: contact@verserindex.com</p>
        </div>
    );
};

export default About;
