import React from 'react';
import { Link } from 'react-router-dom';
import './About.css'; // If you have specific styles for the About page

const Terms = () => {
    return (
        <div className="about-container">
            <h1>Verser Terms of Use</h1>
            <p>Effective: February 12, 2024</p>
            <p>
            By accessing or using our website, you agree to be bound by these Terms of Use ("Terms"). If you do not agree to these Terms, please do not use our services.</p>
            
            <h4>1. Use of the Website</h4>
            
            <p>Verser is provided solely for your personal and non-commercial use. You are granted a limited, non-exclusive right to access and use Verser for the purpose of searching for information about published interviews and contributing to the index of interviews.</p>
            
            <h4>2. Intellectual Property Rights</h4>
            
            <p>All content on Verser, including but not limited to text, graphics, logos, images, and compilation of data, is the property of Verser or its content suppliers and is protected by copyright and other intellectual property laws. You may not copy, reproduce, republish, download, post, broadcast, transmit, sell, exploit, or distribute any part of Verser or its content without our prior written consent, except as permitted under these Terms.</p>
            
            <h4>3. User Contributions</h4>
            <p>Verser allows you to contribute by adding new interviews or editing information about interviews already in the index. However, we reserve the right to reject any submission for any reason without notification. As we do not require account creation for contributions, we will not notify you about the status of your submissions or the reasons for acceptance or rejection.</p>
            
            <p>When contributing, you agree not to submit false information. Verser may use the information you contribute for commercial purposes. By contributing information, you grant Verser a non-exclusive, royalty-free, perpetual, and irrevocable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media.</p>
            
            <h4>4. Prohibited Use</h4>
            
            <p>You may not use Verser:</p>
            <ul>
                <li>For any unlawful purpose.</li>
                <li>To submit false or misleading information.</li>
                <li>To copy, reproduce, republish, upload, post, transmit, or distribute any content from Verser without our express written permission.</li>
                <li>To use automated systems, including but not limited to robots, spiders, or offline readers, to access Verser for any purpose without our express written permission, except for search engines and the like that comply with our robots.txt file (https://www.verserindex.com/robots.txt).</li>
            </ul>
            
            <h4>5. Disclaimer of Warranties</h4>
            
            <p>Verser is provided on an "as is" and "as available" basis without any warranties of any kind, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
            
            <h4>6. Limitation of Liability</h4>
            
            <p>Verser shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages resulting from your use of or inability to use the website.</p>
            
            <h4>7. Changes to Terms</h4>
            
            <p>Verser reserves the right, at its sole discretion, to modify or replace these Terms at any time. By continuing to access or use our website after those revisions become effective, you agree to be bound by the revised terms.</p>
            
            <h4>8. Contact Us</h4>
            <p>If you have any questions about these Terms, please contact us at contact@verserindex.com</p>
        </div>
    );
};

export default Terms;
