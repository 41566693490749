import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Edit.css'; 

const Add = () => {
    const [url, setUrl] = useState('');
    const [comments, setComments] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [interviewTitle, setInterviewTitle] = useState('');
    const [interviewDate, setInterviewDate] = useState('');
    const [interviewees, setInterviewees] = useState('');
    const [interviewers, setInterviewers] = useState('');
    const [publisher, setPublisher] = useState('');
    const [topics, setTopics] = useState('');
    const [people, setPeople] = useState('');
    const [works, setWorks] = useState('');
    const [places, setPlaces] = useState('');

    useEffect(() => {
        document.title = "Add interview - Verser";
        window.scrollTo(0, 0);
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!url.trim()) {
            alert("A URL is required to add an interview.");
            return;
        }

        const userId = process.env.REACT_APP_USER_ID || '123'; 
        const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

        const response = await fetch(`${baseUrl}/api/contributions/new`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId, url, comments, interviewTitle, interviewDate, interviewees,
                interviewers, publisher, topics, people, works, places
            }),
        });

        if (response.ok) {
            setSubmitted(true);
        } else {
            alert('Error submitting the interview. Please try again.');
        }
    };

    const handleAddMore = () => {
        setSubmitted(false);
        setUrl('');
        setComments('');
        setInterviewTitle('');
        setInterviewDate('');
        setInterviewees('');
        setInterviewers('');
        setPublisher('');
        setTopics('');
        setPeople('');
        setWorks('');
        setPlaces('');
    };

    if (submitted) {
        return (
            <div className='add-container'>
                <h2 className='add-header'>Thank you for contributing!</h2>
                <p className='tips-thanks'>Please allow time for your contribution to be reviewed before it will appear on the site.</p>
                <Link to="/add" onClick={handleAddMore}>Add more interviews</Link>
            </div>
        );
    }

    return (
        <div>
            <h2 className='edit-header'>Add an interview to the index</h2>
            <form onSubmit={handleSubmit} className="edit-form">
                <label>URL (required)
                    <div><input
                        type="text"
                        value={url} 
                        onChange={(e) => setUrl(e.target.value)} 
                        placeholder="Add a URL"
                        className="edit-input" 
                    /></div>
                </label>

                {/* Advanced section starts here */}
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <h3>Add detail...</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <label>Title
                            <div><input
                                type="text"
                                value={interviewTitle}
                                onChange={(e) => setInterviewTitle(e.target.value)}
                                placeholder="Add an interview title"
                                className="edit-input" 
                            /></div>
                        </label>

                        <label>Date
                            <div><input
                                type="date"
                                value={interviewDate} 
                                onChange={(e) => setInterviewDate(e.target.value)} 
                                className="edit-input" 
                            /></div>
                        </label>

                        <label>Interviewees
                        <div className='tips'>Separate names with semicolons, and if known, put person IDs in parentheses, like this: <i>Dolly Parton (7343); Barack Obama (120)...</i> Find IDs in person page URLs.</div>
                        <textarea
                                value={interviewees} 
                                onChange={(e) => setInterviewees(e.target.value)} 
                                placeholder="Add interviewees"
                                className="edit-input"
                            />
                        </label>

                        <label>Interviewers
                        <div className='tips'>Separate names with semicolons, and if known, put person IDs in parentheses, like this: <i>Dolly Parton (7343); Barack Obama (120)...</i> Find IDs in person page URLs.</div>
                        <textarea
                                value={interviewers} 
                                onChange={(e) => setInterviewers(e.target.value)} 
                                placeholder="Add interviewers"
                                className="edit-input"
                            />
                        </label>

                        <label>Publisher
                        <div className='tips'>If known, add publisher ID in parentheses, like this: <i>C-SPAN (12)</i> Find IDs in publisher page URLs.</div>
                            <div><input
                                type="text"
                                value={publisher} 
                                onChange={(e) => setPublisher(e.target.value)} 
                                placeholder="Add a publisher"
                                className="edit-input" 
                            /></div>
                        </label>

                        <label>Topics discussed
                        <div className='tips'>Separate topics with semicolons.</div>
                            <textarea
                                value={topics} 
                                onChange={(e) => setTopics(e.target.value)}
                                placeholder="Add topics discussed"
                                className="edit-input"
                            />
                        </label>

                        <label>People mentioned
                        <div className='tips'>Separate names with semicolons, and if known, put person IDs in parentheses, like this: <i>Dolly Parton (7343); Barack Obama (120)...</i> Find IDs in person page URLs.</div>
                            <textarea
                                value={people} 
                                onChange={(e) => setPeople(e.target.value)}
                                placeholder="Add people mentioned"
                                className="edit-input"
                            />
                        </label>

                        <label>Works mentioned
                        <div className='tips'>Separate works with semicolons.</div>
                            <textarea
                                value={works} 
                                onChange={(e) => setWorks(e.target.value)} 
                                placeholder="Add works mentioned"
                                className="edit-input"
                            />
                        </label>

                        <label>Places mentioned
                        <div className='tips'>Separate places with semicolons.</div>
                            <textarea
                                value={places} 
                                onChange={(e) => setPlaces(e.target.value)} 
                                placeholder="Add places mentioned"
                                className="edit-input"
                            />
                        </label>

                        <label>Other comments
                            <textarea
                                value={comments} 
                                onChange={(e) => setComments(e.target.value)} 
                                placeholder="Add other information"
                                className="edit-input"
                            />
                        </label>
                    </AccordionDetails>
                </Accordion>
                {/* Advanced section ends here */}
                
                <div className='submit-button-container'>
                    <button type="submit">Submit</button>
                </div>
            </form>

            <p className='tips'>
            For <b>bulk submissions</b>, send a CSV file to contact@verserindex.com. Please use the columns names and format from the form above.  
            </p>
        </div>
    );
};

export default Add;