import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className='footer'>
        <hr className="footer-line" />
            <p className="footer-text">We're building the internet's best index of interviews.<br></br>You can help by <Link to="/add">adding interviews</Link>.<br></br><br></br>
                © {currentYear} Verser
            </p>
        </div>
    );
};

export default Footer;
