import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import Search from './Search';
import './SearchResults.css';

const SearchResults = () => {
    const [searchParams] = useSearchParams();
    const query = searchParams.get('q') || '';
    const [results, setResults] = useState({ people: [], publishers: [], interviews: [] });
    const [peopleOffset, setPeopleOffset] = useState(0);
    const [publishersOffset, setPublishersOffset] = useState(0);
    const [peopleLimit, setPeopleLimit] = useState(5);
    const [publishersLimit, setPublishersLimit] = useState(5);
    const [interviewsOffset, setInterviewsOffset] = useState(0);
    const [interviewsLimit, setInterviewsLimit] = useState(20);
    const [morePeopleAvailable, setMorePeopleAvailable] = useState(false);
    const [morePublishersAvailable, setMorePublishersAvailable] = useState(false);
    const [moreInterviewsAvailable, setMoreInterviewsAvailable] = useState(false);

    const limit = 5;

    useEffect(() => {
        // Reset offsets and limits to their initial values
        setPeopleOffset(0);
        setPublishersOffset(0);
        setInterviewsOffset(0);
        setPeopleLimit(5); // Assuming 5 is your initial limit
        setPublishersLimit(5); // Assuming 5 is your initial limit
        setInterviewsLimit(20); // Assuming 20 is your initial limit
    
        // Initial fetch for results with updated offsets and limits
        fetchResults(0, 0, 5, 5, 20); // You'll need to modify the fetchResults function accordingly
    }, [query]);

    const fetchMorePeople = async () => {
        const newLimit = peopleLimit + 10; // Increase the limit by 10
        const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
        const response = await fetch(`${apiUrl}/api/search?q=${encodeURIComponent(query)}&peopleLimit=${newLimit}`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setResults(prevResults => ({...prevResults, people: data.people}));
        setPeopleLimit(newLimit); // Update the limit after successfully fetching more results
    
        // Update the 'morePeopleAvailable' state based on the backend response
        setMorePeopleAvailable(data.morePeopleAvailable);
    };
        
    const fetchMorePublishers = async () => {
        const newLimit = publishersLimit + 10; // Increase the limit by 10
        const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
        const response = await fetch(`${apiUrl}/api/search?q=${encodeURIComponent(query)}&publishersLimit=${newLimit}`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setResults(prevResults => ({...prevResults, publishers: data.publishers}));
        setPublishersLimit(newLimit); // Update the limit after successfully fetching more results
        setMorePublishersAvailable(data.morePublishersAvailable);
    };

    const fetchMoreInterviews = async () => {
        const newOffset = interviewsOffset + interviewsLimit; // Update offset based on the current limit
        const newLimit = 40; // Load 40 more interviews each time
        const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
        const response = await fetch(`${apiUrl}/api/search?q=${encodeURIComponent(query)}&interviewsOffset=${newOffset}&interviewsLimit=${newLimit}`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setResults(prevResults => ({
            ...prevResults,
            interviews: [...prevResults.interviews, ...data.interviews]
        }));
        setInterviewsOffset(newOffset); // Update offset after fetching
        setInterviewsLimit(newLimit); // Update limit if you plan to use this for subsequent fetches
        setMoreInterviewsAvailable(data.moreInterviewsAvailable);

    };
    
    const fetchResults = async (newPeopleOffset, newPublishersOffset, newPeopleLimit, newPublishersLimit, newInterviewsLimit) => {
        try {
            const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
            // Use the new offsets and limits
            const response = await fetch(`${apiUrl}/api/search?q=${encodeURIComponent(query)}&peopleOffset=${newPeopleOffset}&publishersOffset=${newPublishersOffset}&peopleLimit=${newPeopleLimit}&publishersLimit=${newPublishersLimit}&interviewsLimit=${newInterviewsLimit}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setResults(data); // Reset the results with the new data
            
            // Update the flags for more available results
            setMorePeopleAvailable(data.morePeopleAvailable);
            setMorePublishersAvailable(data.morePublishersAvailable);
            setMoreInterviewsAvailable(data.moreInterviewsAvailable);
            
            // Reset document title
            document.title = `${query} - Verser`;
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    const handleShowMorePeople = () => {
        const newOffset = peopleOffset + limit;
        fetchResults(newOffset, publishersOffset);
        setPeopleOffset(newOffset);
    };

    const handleShowMorePublishers = () => {
        const newOffset = publishersOffset + limit;
        fetchResults(peopleOffset, newOffset);
        setPublishersOffset(newOffset);
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'Unknown Date';
    
        // Parse the ISO date string
        const parts = dateString.split('-');
        if (parts.length < 3) {
            return 'Invalid Date'; // or return the original dateString to handle unexpected formats gracefully
        }
    
        const year = parts[0];
        const monthIndex = parseInt(parts[1], 10) - 1; // Convert "01" to 0 (January), "02" to 1 (February), etc.
        const day = parseInt(parts[2], 10); // Remove leading zeros
    
        // Array of month names
        const monthNames = ["January", "February", "March", "April", "May", "June", 
                            "July", "August", "September", "October", "November", "December"];
    
        // Check if monthIndex is valid
        if (monthIndex < 0 || monthIndex >= monthNames.length) {
            return 'Invalid Date'; // Month index out of range
        }
    
        const monthName = monthNames[monthIndex];
    
        // Construct the formatted date string
        return `${monthName} ${day}, ${year}`;
    };
    
    
    
    const safeSplit = (data) => {
        if (!data) return [];
        return data.split('|').map(detail => {
            const parts = detail.split(':');
            return { id: parts[0]?.trim(), name: parts[1]?.trim() };
        });
    };

    return (
        <div className="search-results">

            {/* <Search onSearch={onSearch} defaultQuery={query} /> */}

            {Array.isArray(results.people) && (
                <div>
                    <h2>People</h2>
                    {results.people.length > 0 ? (
                        results.people.map((person, index) => (
                            <p key={index}><Link to={`/person/${person.PersonID}`}>{person.Name}</Link></p>
                        ))
                    ) : (
                        <p className='null-message'>No interviewers or interviewees found</p>
                    )}
                    {morePeopleAvailable && (
                        <span className="show-more" onClick={fetchMorePeople}>Show more...</span>
                    )}
                </div>
            )}

            {Array.isArray(results.publishers) && (
                <div>
                    <h2>Publishers</h2>
                    {results.publishers.length > 0 ? (
                        results.publishers.map((publisher, index) => (
                            <p key={index}><Link to={`/publisher/${publisher.PublisherID}`}>{publisher.PublisherName}</Link></p>
                        ))
                    ) : (
                        <p className='null-message'>No publishers found</p>
                    )}
                    {morePublishersAvailable && (
                        <span className="show-more" onClick={fetchMorePublishers}>Show more...</span>
                    )}
                </div>
            )}

            {Array.isArray(results.interviews) && (
                <div>
                <h2>Interviews</h2>
                {results.interviews.length > 0 ? (
                    results.interviews.map((interview, index) => {
                        const interviewerDetails = safeSplit(interview.InterviewerIDsAndNames);
                        const intervieweeDetails = safeSplit(interview.IntervieweeIDsAndNames);

                        return (
                            <div key={index} className="search-result-item">
                                {interview.URL && (
                                    <a href={interview.URL} target="_blank" rel="noopener noreferrer">
                                    <h3>{interview.PageTitle}</h3>
                                </a>
                                )}
                                <div>
                                    {intervieweeDetails.map((detail, idx) => (
                                        <React.Fragment key={detail.id || idx}>
                                            {idx > 0 && ", "}
                                            <Link to={`/person/${detail.id}`}>{detail.name}</Link>
                                        </React.Fragment>
                                    ))}
                                    {' interviewed by '}
                                    {interviewerDetails.map((detail, idx) => (
                                        <React.Fragment key={detail.id || idx}>
                                            {idx > 0 && ", "}
                                            <Link to={`/person/${detail.id}`}>{detail.name}</Link>
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div>
                                    {formatDate(interview.Date)}
                                    <span> | <Link to={`/publisher/${interview.PublisherID}`}>
                                        {interview.PublisherName}
                                    </Link></span>
                                </div>
                                <p className='search-result-details-link'><Link to={`/interview/${interview.InterviewID}`} className='search-result-details-link'>Details</Link>&nbsp; | &nbsp;<Link to={`/edit/${interview.InterviewID}`} className='search-result-details-link'>Edit</Link></p>
                            </div>
                        );
                    })
                ) : (
                    <p className='null-message'>No interviews found</p>
                )}
                {moreInterviewsAvailable && (
                    <span className="show-more-interviews" onClick={fetchMoreInterviews}>Show more interviews...</span>
                )}
            </div>
        )}

        <div className="add-interview-message">
            <p>Let us know what's missing. <Link to="/add">Add interviews</Link></p>
        </div>
    </div>
);
};

export default SearchResults;
