import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Link } from 'react-router-dom';
import Search from './components/Search';
import SearchResults from './components/SearchResults';
import PersonDetail from './components/Person';
import PublisherDetail from './components/Publisher';
import InterviewDetail from './components/Interview';
import Add from './components/Add';
import Edit from './components/Edit';
import Footer from './components/Footer.js';
import About from './components/About';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Review from './components/Review';
import './App.css';

// This function sends page views to Google Analytics every time the route changes
const usePageTracking = () => {
    const location = useLocation();
    useEffect(() => {
        // Assuming gtag function is already defined in the index.html as part of the GA snippet
        window.gtag('config', 'G-HJ1864BX5B', {
            'page_path': location.pathname + location.search
        });
    }, [location]);
};

const App = () => {
    const [searchResults, setSearchResults] = useState({ people: [], publishers: [], interviews: [] });

    const handleSearch = async (query) => {
        try {
            const apiUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000'; // Fallback to localhost if the variable is not defined
            const response = await fetch(`${apiUrl}/api/search?q=${encodeURIComponent(query)}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setSearchResults(data); // Set the entire response object
        } catch (error) {
            console.error('Error during search:', error);
        }
    };

    return (
        <Router>
            <div className='App'>
                <Header handleSearch={handleSearch} />
                <Routes>
                    <Route path="/" element={
                        <><div className="home-header">
                            <h1 className='App-logo'><span className='verytight'>V</span>erser</h1>
{/*                             <span className='home-subtitle'>Discover interviews with interesting people</span> */}
                            <div className='home-search-container'><Search onSearch={handleSearch} /></div>
{/*                             <hr className="homepage-line" /> */}
                        </div></>
                    } />
                    <Route path="/search" element={<SearchResults results={searchResults} onSearch={handleSearch} />} />
                    <Route path="/person/:id" element={<PersonDetail />} />
                    <Route path="/publisher/:id" element={<PublisherDetail />} />
                    <Route path="/interview/:interviewId" element={<InterviewDetail />} />
                    <Route path="/add" element={<Add />} />
                    <Route path="/edit/:interviewId" element={<Edit />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/review" element={<Review />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
};

const Header = ({ handleSearch }) => {
    usePageTracking(); // This uses the custom hook to track page changes
    const location = useLocation();
    const isHomePage = location.pathname === '/';

    useEffect(() => {
        // Set the title for the home page
        if (isHomePage) {
            document.title = 'Verser - find interviews with interesting people';
        }
    }, [isHomePage]);

    if (!isHomePage) {
        return (
            <div className="header-container">
                <div className="non-home-header">
                    <Link to="/" className="logo-link">
                        <h1 className='App-logo'><span className='tight'>V</span>erser</h1>
                    </Link>
                    <div className="search-container">
                        <Search onSearch={handleSearch} />
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default App;
