// src/components/Publisher.js
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './SearchResults.css';

const PublisherDetail = () => {
    const { id } = useParams();
    const [publisherName, setPublisherName] = useState('Loading...');
    const [interviews, setInterviews] = useState([]);
    const [sort, setSort] = useState('Recent'); // Added state for sorting

    const formatDate = (dateString) => {
        if (!dateString) return 'Unknown Date';
    
        // Parse the ISO date string
        const parts = dateString.split('-');
        if (parts.length < 3) {
            return 'Invalid Date'; // or return the original dateString to handle unexpected formats gracefully
        }
    
        const year = parts[0];
        const monthIndex = parseInt(parts[1], 10) - 1; // Convert "01" to 0 (January), "02" to 1 (February), etc.
        const day = parseInt(parts[2], 10); // Remove leading zeros
    
        // Array of month names
        const monthNames = ["January", "February", "March", "April", "May", "June", 
                            "July", "August", "September", "October", "November", "December"];
    
        // Check if monthIndex is valid
        if (monthIndex < 0 || monthIndex >= monthNames.length) {
            return 'Invalid Date'; // Month index out of range
        }
    
        const monthName = monthNames[monthIndex];
    
        // Construct the formatted date string
        return `${monthName} ${day}, ${year}`;
    };

    const safeSplit = (data) => {
        if (!data) return [];
        return data.split('|').map(detail => {
            const parts = detail.split(':');
            return { name: parts[0]?.trim(), id: parts[1]?.trim() };
        });
    };

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000'; // Fallback to localhost
        // Fetch publisher name
        fetch(`${baseUrl}/api/publisher/${id}/name`)
            .then(response => response.json())
            .then(data => {
                setPublisherName(data.name);
                // Set page title after fetching and setting plublisher's name
                document.title = `${data.name} interviews - Verser`;
            })
            .catch(error => console.error('Error fetching publisher name:', error));

        // Fetch interviews
        fetch(`${baseUrl}/api/publisher/${id}/interviews?sort=${sort}`)
            .then(response => response.json())
            .then(data => setInterviews(data))
            .catch(error => console.error('Error fetching interviews:', error));
    }, [id, sort]);

    return (
        <div className='search-results'>
            <h1>{publisherName}</h1>
            <div className='sort-filter'>
                <span 
                    className={`sort-link ${sort === 'Recent' ? 'active' : ''}`} 
                    onClick={() => setSort('Recent')}
                >Recent</span>&nbsp;|&nbsp;
                <span 
                    className={`sort-link ${sort === 'Oldest' ? 'active' : ''}`} 
                    onClick={() => setSort('Oldest')}
                >Oldest</span>
            </div>
            <div className='search-results'>
{/*                 <h2>Interviews</h2> */}
                {interviews.map((interview, index) => {
                    const intervieweeDetails = safeSplit(interview.IntervieweeDetails);
                    const interviewerDetails = safeSplit(interview.InterviewerDetails);

                    return (
                        <div key={index} className="search-result-item">
                                
                                {interview.URLs && (
                                    <a href={interview.URLs.split(',')[0]} target="_blank" rel="noopener noreferrer">
                                        <h3>{interview.PageTitle}</h3>
                                    </a>
                                )}
                                <div>
                                    {intervieweeDetails.map((detail, idx) => (
                                        <React.Fragment key={detail.id || idx}>
                                            {idx > 0 && ", "}
                                            <Link to={`/person/${detail.id}`}>{detail.name}</Link>
                                        </React.Fragment>
                                    ))}
                                    {' interviewed by '}
                                    {interviewerDetails.map((detail, idx) => (
                                        <React.Fragment key={detail.id || idx}>
                                            {idx > 0 && ", "}
                                            <Link to={`/person/${detail.id}`}>{detail.name}</Link>
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div>
                                    {formatDate(interview.Date)}
                                    <span> | <Link to={`/publisher/${interview.PublisherID}`}>
                                        {interview.PublisherName}
                                    </Link></span>
                                </div>
                                <p className='search-result-details-link'>
                                <Link to={`/interview/${interview.InterviewID}`} className='search-result-details-link'>Details</Link>&nbsp; | &nbsp;<Link to={`/edit/${interview.InterviewID}`} className='search-result-details-link'>Edit</Link></p>
                            </div>
                    );
                })}
                <div className="add-interview-message">
                    <p>Let us know what's missing. <Link to="/add">Add interviews</Link></p>
                </div>
            </div>
        </div>
    );
};

export default PublisherDetail;
